<template>
    <div>
        <section class="hero-area " id="home" :class="tour ? 'overlay-tour image-background' : 'overlay'">
            <div class="block w-100">
                <div class="navbar-sty">
                    <!-- <div class="box-media">
                        <i class="bi bi-facebook"></i>
                    </div> -->
                    <a href=" https://www.instagram.com/golomb.odonto/" class="box-media" target="_blank">
                        <i class="bi bi-instagram"></i>
                    </a>
                    <div class="duvidas">
                        <span class="text-duvidas">{{ t.doubts_call_on }}</span>
                        <a class="contato" target="_blank"
                            href="https://wa.me/5551980220040?text=Olá%20Golomb%20Odonto,%20gostaria%20de%20marcar%20uma%20consulta%20!"><i
                                class="bi bi-whatsapp"></i> (51) 98022-0040 </a>
                    </div>
                </div>
                <div class="org-card">
                    <div class="px-5 size-box">
                        <img src="@/assets/image/brand.webp" class="img-fluid size-img" alt="" />

                        <div class="intro-heading">
                            <span class="logo-mark">Golomb Odonto</span>
                        </div>
                        <div class="intro-lead-in" v-if="!tour">{{ t.quality_service_for_the_whole_family }}
                        </div>
                        <div class="intro-lead-in" v-else>
                            {{ t.virtual_tour }}
                        </div>
                        <div class="subtitle-intro" v-if="!tour">
                            {{ t.your_smile_deserves_care }}
                        </div>
                        <div class="subtitle-intro" v-else>
                            {{ t.welcoming_modern_space }}
                        </div>
                        <div class="align-btn">
                            <a href="#about" class="btn-transparent" v-html="t.explore_us"></a>
                        </div>
                    </div>
                    <!-- <div class="org-img" v-if="checkVirtual">
                        <img src="@/assets/image/image-banner.png" alt="" srcset="" />
                    </div> -->
                </div>
            </div>
        </section>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
        <a href="https://wa.me/5551980220040?text=Olá%20Golomb%20Odonto,%20gostaria%20de%20marcar%20uma%20consulta%20!"
            style="position:fixed;width:60px;height:60px;bottom:40px;right:40px;background-color:#25d366;color:#FFF;border-radius:50px;text-align:center;font-size:30px;box-shadow: 1px 1px 2px #888;
  z-index:1000;" target="_blank">
            <i style="margin-top:16px" class="fa fa-whatsapp"></i>
        </a>

        <vue-position-sticky>
            <MenuToolbar :tour="tour" />
        </vue-position-sticky>
    </div>
</template>
<script></script>
<script>
import MenuToolbar from "@/components/MenuToolbar.vue";
import Pt from "@/language/Pt.json";
import {eventBus} from "@/main";

export default {
    props: {
        tour: Boolean
    },
    components: {MenuToolbar},
    data() {
        return {
            t: [],
            translatePt: [],
            checkVirtual: null
        };
    },
    created() {
        this.translatePt = Pt;
        this.t = this.translatePt[0];

        eventBus.$on("pt", (pt) => {
            this.t = pt;
        });
        eventBus.$on("en", (en) => {
            this.t = en;
        });
        eventBus.$on("es", (es) => {
            this.t = es;
        });

        // let getUrl = window.location.hash
        // this.checkVirtual = getUrl === "#virtual-tour" ? true : false;

        // eventBus.$on("virtualTour", (val) => {
        //     this.checkVirtual = val;

        //     console.log(this.checkVirtual)
        // });
    },
};
</script>
<style>
.image-background {
    background-image: url('@/assets/image/porta.webp');

}

.overlay {
    position: relative;
}

.overlay::before {
    content: "";
    background: linear-gradient(40deg, rgba(0, 0, 0, 1) 0%, rgb(44, 44, 44) 73%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    left: 0;
}

.overlay-tour::before {
    content: "";
    background: linear-gradient(60deg, rgba(0, 0, 0, 1) 0%, rgba(10, 10, 10, 0.685) 73%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    left: 0;
}

.navbar-sty {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 999;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: transparent;
    border-bottom: 1px solid rgba(128, 128, 128, 0.548);
}

.box-media {
    border-right: 1px solid rgba(128, 128, 128, 0.548);
    border-left: 1px solid rgba(128, 128, 128, 0.548);
    padding: 6px 18px 10px 18px;
    font-size: 20px;
    color: #a3a3a3;
}

.box-media:hover {
    cursor: pointer;
    color: white;
}

.duvidas {
    padding: 0 25px;
    width: 40%;
    text-align: start;
    font-size: 14px;
    font-family: "Georgia Pro light";
    word-spacing: 2px;
    letter-spacing: 1px;
    color: #e7e7e7;
}

.hero-area {
    background-size: cover;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.intro-heading {
    font-size: 7rem;
    color: #fff;
    text-transform: capitalize;
    font-weight: 700;
    text-align: start;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.hero-area .block {
    text-align: center;
    z-index: 99;
}

.logo-mark {
    color: #7e655b;
}

.intro-lead-in {
    font-size: 32px;
    color: #ebd79f;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 10px;
    font-family: "Georgia Pro CondBold";
    text-align: start;
    word-spacing: 3px;
}

.subtitle-intro {
    font-size: 24px;
    font-family: "Mulish";
    text-align: center;
    word-spacing: 3px;
    color: #f6e8c7;
    margin: 25px 0;
}

.size-box {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.org-card {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px 0;
}

/* .brand-image {
    width: 75px;
} */

.btn-transparent {
    color: #f6e8c7;
    text-decoration: none;
    border: 1px solid #ebd79f;
    font-family: "Mulish";
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    display: inline-block;
    transition: all 0.3s ease;
    letter-spacing: 3px;
}

.btn-transparent:hover {
    background: #7e655b;
    color: #ebd79f;
    text-decoration: none;
}


.contato {
    margin: 0 7px;
    text-decoration: none;
    font-family: "Mulish";
    color: #e7e7e7;
}

.contato:hover {
    color: #ebd79f;
    cursor: pointer;
    text-decoration: none;
}

.intro-heading,
.align-btn,
.intro-lead-in,
.subtitle-intro {
    opacity: 0;
    transform: translateY(100%);
    animation: slideUp 1s forwards;
}

.intro-heading {
    animation-delay: 0.3s;
}

.intro-lead-in {
    animation-delay: 0.6s;
}

.subtitle-intro {
    animation-delay: 0.9s;
    width: 50%;
}

.align-btn {
    text-align: start;
    cursor: pointer;
}

.size-img {
    width: 170px;
}


@keyframes slideUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 1880px) {
    .intro-heading {
        font-size: 6rem;
    }

    .intro-lead-in {
        font-size: 1.7rem;
    }

    .subtitle-intro {
        font-size: 1.3rem;
    }
}

@media (max-width: 1160px) {
    .intro-heading {
        text-align: center;
        font-size: 6rem;
    }

    .intro-lead-in {
        font-size: 1.7rem;
    }

    .subtitle-intro {
        font-size: 1.3rem;
    }

    .text-duvidas {
        display: none;
    }

    .subtitle-intro {
        width: 100%;
    }

}

@media (max-width: 880px) {
    .intro-heading {
        font-size: 4rem;
    }

    .intro-lead-in {
        font-size: 1.4rem;
    }

    .subtitle-intro {
        font-size: 1rem;
        text-align: center;
    }
}

@media (max-width: 590px) {
    .intro-heading {
        font-size: 2rem;
    }

    .intro-lead-in {
        font-size: 1.2rem;
        text-align: center;
    }

    .size-img {
        width: 100px;
    }

    .contato {
        font-size: 13px;
    }
}

@media (max-height: 607px) {
    .intro-heading {
        font-size: 3rem;
    }

    .intro-lead-in {
        font-size: 1.7rem;
        text-align: center;
    }

    .size-img {
        width: 130px;
    }

    .contato {
        font-size: 13px;
    }
}
</style>
