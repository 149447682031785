<template>
    <div class="home" v-if="!virtualTour">
        <Home />
        <About />
        <Team />
        <Jobs />
        <Testimonials />
        <Contact />

    </div>
    <div v-else>
        <TourVirtual />
    </div>
</template>

<script>
import Home from '@/components/Home.vue'
import About from '@/components/About.vue'
import Jobs from '@/components/Jobs.vue'
import Team from '@/components/Team.vue'
import Contact from '@/components/Contact.vue'
import Testimonials from '@/components/Testimonial.vue'


import TourVirtual from '@/components/tourVirtual/TourVirtual.vue'

import { eventBus } from "@/main";


export default {
    components: {
        Home,
        About,
        Team,
        Jobs,
        Testimonials,
        Contact,
        TourVirtual
    },
    data() {
        return {
            virtualTour: false
        }
    },
    created() {
        eventBus.$on("virtualTour", (val) => {
            this.virtualTour = val;
        });
    },
}
</script>
<style>
.history {
    padding: 1rem 0 2rem;
    font-size: 20px;
    font-style: italic;
    font-family: 'Georgia Pro bold';
}
</style>
