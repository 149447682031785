<template>
    <div>
        <section id="our-solution" class="section bg-one section">
            <div class="container">
                <div class="row">
                    <div class="mb-5">
                        <img src="../assets/image/icon.webp" width="50">
                        <div class="titulo-sobre">{{ translateAll.our == 'Nossa' ? 'Nossas' : translateAll.our }} <span
                                class="texto-destaque">especialidades</span></div>
                        <div class="separator"></div>
                        <div class="text-card " :class="sizeWidth > 770 ? 'text-center' : 'text-start'">
                            <span class="mb-3">
                                {{ translateAll.highest_qualifications }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="d-flex justify-content-center flex-wrap">
                        <div class="card-func " v-for="item in especialidades">
                            <img :src="item.img" />
                            <div class="text-center pl-3">
                                <span class="text-card-title">{{ item.name }}</span>
                                <span class="text-card-desc">{{ item.description }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="separator my-5"></div>
                </div>
            </div>
            <div class="d-flex justify-content-center ">
                <div class="d-flex justify-content-between flex-wrap org-jobs">
                    <div class="col-12 col-lg-6 col-md-10 d-flex flex-wrap">
                        <div class="mx-1">
                            <img src="../assets/image/noturno.webp" class="img-fluid sty-img">
                        </div>
                        <div class=" d-flex flex-column">
                            <div class="mx-1 mb-2">
                                <img src="../assets/image/domiciliar.webp" class="img-fluid sty-img">
                            </div>
                            <div class="mx-1">
                                <img src="../assets/image/cirurgia.webp" class="img-fluid sty-img">
                            </div>
                        </div>
                    </div>
                    <div class=" col-12 col-lg-6 col-md-10">
                        <div class="text-card my-5">
                            <span class="txt-destaque-jobs">
                                {{ translateAll.excellence_innovation }}
                            </span>
                            <span class="mt-3" v-html="translateAll.hospital_home_care"> </span>
                            <span class="mt-3">
                                {{ translateAll.personalized_care }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-start">
                            <div class="align-btn">
                                <a class="btn-transparent" target="_blank"
                                    href="https://wa.me/5551980220040?text=Olá%20Golomb%20Odonto,%20gostaria%20de%20marcar%20uma%20consulta%20!">{{
                                        translateAll.schedule_consultation }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { eventBus } from "@/main";
import Pt from "@/language/Pt.json";
import En from "@/language/En.json";
import Es from "@/language/Es.json";

export default {
    data() {
        return {
            translateAll: [],

            especialidades: [],
            sizeWidth: window.innerWidth,
        };
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    created() {
        let languageSite = new URLSearchParams(window.location.search).get("language");

        if (languageSite === "pt") {
            this.translateAll = Pt[0];

        } else if (languageSite === "en") {
            this.translateAll = En[0];

        } else if (languageSite === "es") {
            this.translateAll = Es[0];

        } else {
            this.translateAll = Pt[0];
        }

        eventBus.$on("pt", (pt) => {
            this.translateAll = pt;
            this.jobs()
        });
        eventBus.$on("en", (en) => {
            this.translateAll = en;
            this.jobs()
        });
        eventBus.$on("es", (es) => {
            this.translateAll = es;
            this.jobs()
        });

        this.jobs()
    },
    methods: {
        handleResize() {
            this.sizeWidth = window.innerWidth;
        },
        jobs() {
            this.especialidades = [
                {
                    name: this.translateAll.endodontics,
                    description: this.translateAll.endodontics_description,
                    img: require("../assets/image/trabalhos/endodontia.svg"),
                },
                {
                    name: this.translateAll.periodontics,
                    description: this.translateAll.periodontics_description,
                    img: require("../assets/image/trabalhos/periodontia.svg"),
                },
                {
                    name: this.translateAll.facial_harmonization,
                    description: this.translateAll.facial_harmonization_description,
                    img: require("../assets/image/trabalhos/harmonizacao.svg"),
                },
                {
                    name: this.translateAll.pediatric_dentistry,
                    description: this.translateAll.pediatric_dentistry_description,
                    img: require("../assets/image/trabalhos/pediatria.svg"),
                },

                {
                    name: this.translateAll.dental_prenatal,
                    description: this.translateAll.dental_prenatal_description,
                    img: require("../assets/image/trabalhos/prenatal.svg"),
                },
                {
                    name: this.translateAll.orthodontics,
                    description: this.translateAll.orthodontics_description,
                    img: require("../assets/image/trabalhos/ortodontia.svg"),
                },
                {
                    name: this.translateAll.dental_veneers,
                    description: this.translateAll.dental_veneers_description,
                    img: require("../assets/image/trabalhos/faceta-dentaria.svg"),
                },
                {
                    name: this.translateAll.teeth_whitening,
                    description: this.translateAll.teeth_whitening_description,
                    img: require("../assets/image/trabalhos/clareamento.svg"),
                },
                {
                    name: this.translateAll.prosthetics,
                    description: this.translateAll.prosthetics_description,
                    img: require("../assets/image/trabalhos/protese.svg"),
                },
                {
                    name: this.translateAll.implants,
                    description: this.translateAll.implants_description,
                    img: require("../assets/image/trabalhos/implante.svg"),
                },
                {
                    name: this.translateAll.bruxism,
                    description: this.translateAll.bruxism_description,
                    img: require("../assets/image/trabalhos/default.svg"),
                },
                {
                    name: this.translateAll.tmj_pain,
                    description: this.translateAll.tmj_pain_description,
                    img: require("../assets/image/trabalhos/default.svg"),
                },
                {
                    name: this.translateAll.oral_surgery_traumatology,
                    description: this.translateAll.oral_surgery_traumatology_description,
                    img: require("../assets/image/trabalhos/cirurgia.svg"),
                },
                {
                    name: this.translateAll.special_needs_patients,
                    description: this.translateAll.special_needs_patients_description,
                    img: require("../assets/image/trabalhos/paciente.svg"),
                },

            ]
        }
    },
};
</script>
<style>
.bg-two {
    padding: 5rem 0;
}

.card-func {
    margin: 15px 15px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    width: 400px;
    background-color: #1a1a18;
    border-bottom: 1px solid transparent;
}

.card-func:hover {
    border-bottom: 1px solid #ebd79f;
}

.text-card-title,
.text-card-desc {
    font-size: 18px;
    margin: 0 0.2rem;
    color: #ebd79f;
    font-family: "Mulish";
    text-align: start;
    display: flex;
}

.text-card-desc {
    font-size: 16px;
    margin: 7px 0 15px;
    color: #e2e2e2;
}

.sty-img {
    border: 1px solid #ebd79f;
}

.org-jobs {
    width: 80%;
}
.org-jobs img{
    border-radius: 7px;
}
.txt-destaque-jobs{
    font-size: 2rem;
    color: #ebd79f;
    font-weight: bold;
}
</style>
