<template>
    <div>
        <Home :tour="true"/>
        <section class="bg-one about section">
            <div class="container">
                <div class="row">
                    <div class="mb-5">
                        <img src="../../assets/image/icon.webp" width="50">
                        <div class="titulo-sobre">
                            {{ translateAll.discover_our }} <span class="texto-destaque">{{ translateAll.space }}</span>
                        </div>
                        <div class="separator"></div>
                        <div class="text-card">
                            <span class="text-center">
                                {{ translateAll.unique_experience }}
                            </span>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <div>
                            <iframe width="380" height="680" allowfullscreen="true" webkitallowfullscreen="true"
                                mozallowfullscreen="true" frameborder="0" class="sty-img rounded-3"
                                :srcdoc="iframeContent"></iframe>
                        </div>
                        <div class="d-flex flex-wrap justify-content-center ">
                            <div class="mx-1 my-2" v-for="(image, index) in images" :key="index">
                                <img :src="image" class=" p-2 galeria-img" @click="openModal(index)" />
                            </div>
                        </div>
                    </div>

                    <div v-if="showModal" class="bg-slide">
                        <div @click="closeModal" class="fechar">
                            {{ translateAll.close }}
                        </div>
                        <div class="content-slide " @click.stop>
                            <swiper ref="mySwiper">
                                <swiper-slide v-for="(image, index) in images" :key="index">
                                    <div class="image-container">
                                        <img :src="image" class="show-image" />
                                        <div class="d-flex justify-content-center align-items-center">
                                            <div class="overlay-slide">
                                                <div class="text">{{ text[index] }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { eventBus } from "@/main";
import Home from '../Home.vue'

import Pt from "@/language/Pt.json";
import En from "@/language/En.json";
import Es from "@/language/Es.json";

import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';


export default {
    components: {
        Home,
        swiper,
        swiperSlide,

    },
    data() {
        return {
            translateAll: [],
            iframeLoaded: false,
            iframeSrc: require('../../assets/image/virtualTour/tour virtual.mp4'),
            showModal: false,
            images: [
                require('../../assets/image/virtualTour/facetas em resina 2.webp'),
                require('../../assets/image/virtualTour/facetas em resina 3.webp'),
                require('../../assets/image/virtualTour/Facetas em resina.webp'),
                require('../../assets/image/virtualTour/Galeria - at bebe.webp'),
                require('../../assets/image/virtualTour/Galeria - At domiciliar.webp'),
                require('../../assets/image/virtualTour/galeria primeira consulta bebe.webp'),
                require('../../assets/image/virtualTour/galeria sedação.webp'),
                require('../../assets/image/virtualTour/preenchimento de olheira.webp'),
                require('../../assets/image/virtualTour/primeira consulta bebe.webp'),
                require('../../assets/image/virtualTour/sedação inalatoria com oxido nitroso.webp'),
            ],
            text: [],

            activeIndex: 0,
        }
    },
    // created() {
    //     let getUrl = window.location.hash
    //     this.tour = getUrl === "#virtual-tour" ? true : false
    // },
    computed: {
        iframeContent() {
            return `
                <html>
                    <body style="margin:0;">
                        <video width="100%" height="100%" controls>
                        <source src="${this.iframeSrc}" type="video/mp4">
                        Your browser does not support the video tag.
                        </video>
                    </body>
                </html>`;
        }
    },

    created() {
        let languageSite = new URLSearchParams(window.location.search).get("language");

        if (languageSite === "pt") {
            this.translateAll = Pt[0];

        } else if (languageSite === "en") {
            this.translateAll = En[0];

        } else if (languageSite === "es") {
            this.translateAll = Es[0];

        } else {
            this.translateAll = Pt[0];
        }

        eventBus.$on("pt", (pt) => {
            this.translateAll = pt;
        });
        eventBus.$on("en", (en) => {
            this.translateAll = en;
        });
        eventBus.$on("es", (es) => {
            this.translateAll = es;
        });

        this.getText();

        eventBus.$on("virtualTour", (val) => {
            this.tour = val;
        });

    },
    methods: {
        openModal(index) {
            this.activeIndex = index;
            this.showModal = true;
            this.$nextTick(() => {
                this.$refs.mySwiper.swiper.slideTo(index);
            });
        },
        closeModal() {
            this.showModal = false;
        },
        getText() {
            this.text = [
                this.translateAll.resin_veneers2,
                this.translateAll.resin_veneers2,
                this.translateAll.resin_veneers2,
                this.translateAll.baby_care,
                this.translateAll.home_care,
                this.translateAll.baby_first_consultation,
                this.translateAll.sedation,
                this.translateAll.dark_circle_filler,
                this.translateAll.baby_first_consultation,
                this.translateAll.inhalation_sedation
            ]
        }

    },
}
</script>
<style>
.bg-slide {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.842);
    /* Fundo com opacidade 0.8 */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
    opacity: 1 !important;
}

.content-slide {
    position: relative;
    width: 80%;
    height: 80%;
    z-index: 1001;
    opacity: 1;
    /* Conteúdo com opacidade 1 */
}

.show-image {
    opacity: 1;
    /* Imagens com opacidade 1 */
}

.galeria-img {
    max-width: 350px;
    /* Ajuste o tamanho conforme necessário */
    height: 225px;
    cursor: pointer;
}

.galeria-img:hover {
    transform: scale(1.1);
    transition: all 0.5s;
}

.show-image {
    max-width: 100%;
    /* Ajuste o tamanho conforme necessário */
    height: 70vh;
}

.fechar {
    font-size: 22px;
    color: white;
    font-weight: bold;
    font-family: "Mulish";
    cursor: pointer;
    border-bottom: 1px solid #e1c571;
    margin-bottom: 10px
}

.overlay-slide {
    position: absolute;
    bottom: 0;
    width: 50%;
    height: 15vh;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, 0.842);
}

.image-container:hover .overlay-slide {
    opacity: 1;
}

.text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
</style>