<template>
    <section class="bg-two">
        <div class="container">
            <div class="row">
                <div class="mb-5">
                    <img src="../assets/image/icon.webp" width="50">
                    <div class="titulo-sobre"><span class="texto-destaque">{{ translateAll.depositions }}</span></div>
                    <div class="separator"></div>
                    <div class="text-card " :class="sizeWidth > 770 ? 'text-center' : 'text-start'">
                        <span class="mb-3">
                            {{ translateAll.see_what_they_say }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap justify-content-center">
            <div class="card-depoimento col-10 col-lg-4 m-4" v-for="dep in depoimentos">
                <div class="img-depoimento col-4 col-lg-3 col-md-3 mb-4 ">
                    <img :src="dep.image" alt="" class="rounded-circle img-fluid">
                </div>
                <div class="col-12 col-lg-8  d-flex flex-column" :class="sizeWidth < 1000 ? 'px-5' : 'px-2'">
                    <div class="depoimento-nome">{{ dep.nome }}</div>
                    <div class="estrelas"></div>
                    <div class="descricao">{{ dep.descricao }}</div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { eventBus } from "@/main";
import Pt from "@/language/Pt.json";
import En from "@/language/En.json";
import Es from "@/language/Es.json";
export default {
    data() {
        return {
            sizeWidth: window.innerWidth,
            depoimentos: []
        }
    },
    created() {
        let languageSite = new URLSearchParams(window.location.search).get("language");

        if (languageSite === "pt") {
            this.translateAll = Pt[0];

        } else if (languageSite === "en") {
            this.translateAll = En[0];

        } else if (languageSite === "es") {
            this.translateAll = Es[0];

        } else {
            this.translateAll = Pt[0];
        }

        eventBus.$on("pt", (pt) => {
            this.translateAll = pt;
            this.dep()
        });
        eventBus.$on("en", (en) => {
            this.translateAll = en;
            this.dep()
        });
        eventBus.$on("es", (es) => {
            this.translateAll = es;
            this.dep()
        });
        this.dep()
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    methods: {
        handleResize() {
            this.sizeWidth = window.innerWidth;
        },
        dep() {
            this.depoimentos = [
                {
                    image: require('../assets/image/depoimentos/depoimento1.webp'),
                    nome: `Adejane Mattei - ${this.translateAll.mother_of} Miguel`,
                    descricao: this.translateAll.testimonial_1
                },
                {
                    image: require('../assets/image/depoimentos/depoimento2.webp'),
                    nome: `Bia Silva - ${this.translateAll.mother_of} Pedro`,
                    descricao: this.translateAll.testimonial_2
                },
                {
                    image: require('../assets/image/depoimentos/depoimento3.webp'),
                    nome: 'Martina Peloso',
                    descricao: this.translateAll.testimonial_3
                },
                {
                    image: require('../assets/image/depoimentos/depoimento4.webp'),
                    nome: 'Dulce Marlene',
                    descricao: this.translateAll.testimonial_4
                },
            ]
        }
    },
}
</script>
<style>
.card-depoimento {
    background-color: #343432;
    padding: 20px 0 30px 0;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.img-depoimento img {
    border: 1px solid #ebd79f;
    padding: 7px;
}

.depoimento-nome {
    text-align: start;

    font-family: "Georgia Pro light";
    letter-spacing: 3px;
    font-size: 26px;
    font-weight: bold;
    font-style: italic;
}

.estrelas {
    display: flex;
}

.estrelas::before {
    content: '★★★★★';
    font-size: 2em;
    background: linear-gradient(90deg, #e1c571 100%, #ddd 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.descricao {
    margin-top: 20px;
    font-size: 1em;
    line-height: 1.5;
    font-family: "Mulish";
    text-align: start;
}

.icon-size {
    font-size: 2.5em;
}

</style>