<template>
    <div>
        <section id="our-team" class=" bg-two">
            <div class="container">
                <div class="row">
                    <div class="mb-5">
                        <img src="../assets/image/icon.webp" width="50">
                        <div class="titulo-sobre">{{ translateAll.our }} <span class="texto-destaque">{{
                            translateAll.team }}</span></div>
                        <div class="separator"></div>
                        <div class="text-card " :class="sizeWidth > 770 ? 'text-center' : 'text-start'">
                            <span class="mb-3">
                                {{ translateAll.most_qualified }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class=" d-flex justify-content-center" v-if="sizeWidth > 770">
                <carousel :autoplay="true" :autoplayTimeout="3000" :loop="true" :perPage="sizeWidth > 1500 ? 3 : 2"
                    class="col-8 d-flex justify-content-center ">
                    <slide v-for="(card, index) in cards" :key="index">
                        <div class="card-jobs p-4 image-overlay">
                            <img :src="card.image" class="img-fluid" />
                            <div class="d-flex flex-column align-items-start mt-3">
                                <div class="overlay-name">{{ card.nome }}</div>
                                <div class="overlay-subtitle" v-html="card.function"></div>
                                <div class="text-card fs-6">{{ card.content }}</div>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
            <div v-if="sizeWidth < 770" class="lines " v-for="card in cards">
                <div class="d-flex justify-content-between align-items-center mx-3 "
                    @click="card.isSelect = !card.isSelect">
                    <div class="text-collapse">{{ card.nome }}</div>
                    <b-icon icon="chevron-up" :class="card.isSelect ? 'rotate-down' : 'rotate-up'"></b-icon>
                </div>
                <div class="text-collpse-content" v-if="card.isSelect">
                    <img :src="card.image" class="img-fluid shape" :class="sizeWidth > 350 ? 'img-mobile' : ''" />
                    <div class="mx-4 org-texto">
                        <div class="overlay-subtitle" v-html="card.function"></div>
                        <div class="overlay-about">{{ card.content }}</div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { eventBus } from "@/main";
import Pt from "@/language/Pt.json";
import En from "@/language/En.json";
import Es from "@/language/Es.json";


export default {
    data() {
        return {
            translateAll: [],

            cards: [],
            sizeWidth: window.innerWidth,
        };
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    created() {
        let languageSite = new URLSearchParams(window.location.search).get("language");

        if (languageSite === "pt") {
            this.translateAll = Pt[0];

        } else if (languageSite === "en") {
            this.translateAll = En[0];

        } else if (languageSite === "es") {
            this.translateAll = Es[0];

        } else {
            this.translateAll = Pt[0];
        }

        eventBus.$on("pt", (pt) => {
            this.translateAll = pt;
            this.translate()
        });
        eventBus.$on("en", (en) => {
            this.translateAll = en;
            this.translate()
        });
        eventBus.$on("es", (es) => {
            this.translateAll = es;
            this.translate()
        });

        this.translate()
    },
    methods: {
        handleResize() {
            this.sizeWidth = window.innerWidth;
        },
        translate() {
            this.cards = [
                {
                    nome: "Karina Golombiewski",
                    function: this.translateAll.maxillofacial_surgery,
                    image: require(`@/assets/image/team/Karina.webp`),
                    isSelect: false,
                    content: this.translateAll.dentistry_graduate
                },
                {
                    nome: "Clara Cavagnoli",
                    function: this.translateAll.pediatric_dentistry,
                    image: require(`@/assets/image/team/Clara.webp`),
                    isSelect: false,
                    content: this.translateAll.pediatric_dentist
                },
                {
                    nome: "Nayara Heidmann",
                    function: this.translateAll.resin_veneers,
                    image: require(`@/assets/image/team/Nayara.webp`),
                    isSelect: false,
                    content: this.translateAll.dental_aesthetics
                },
                {
                    nome: "Douglas Cruz",
                    function: this.translateAll.endodontics_treatment,
                    image: require(`@/assets/image/team/Douglas.webp`),
                    isSelect: false,
                    content: this.translateAll.endodontist
                },
                {
                    nome: "Thiago Casotti",
                    function: this.translateAll.prosthetics_rehabilitation,
                    image: require(`@/assets/image/team/Thiago.webp`),
                    isSelect: false,
                    content: this.translateAll.oral_rehabilitation
                },
                {
                    nome: "Andrea Golombiewski",
                    function: this.translateAll.care_coordinator,
                    image: require(`@/assets/image/team/coordenadora.webp`),
                    isSelect: false,
                    content:
                        "Possui vasta experiência como proprietária de um comércio e de um salão de beleza, onde adquiriu habilidades excepcionais em gestão e atendimento ao cliente. É responsável pela recepção dos pacientes, coordenação das marcações de consulta e pela garantia de um ambiente acolhedor e profissional, sempre focando em proporcionar uma experiência positiva e personalizada para cada cliente.",
                },
            ]
        }
    },
};
</script>
<style>
.card-jobs {
    display: flex;
    background-color: #343432;
    margin: 0 10px;
    border-radius: 8px;
    flex-direction: column;
    height: 690px
}

.image-overlay {
    position: relative;
}

.image-overlay img {
    border-radius: 8px;
}

.overlay-name {
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
    color: #e1c571;
}

.overlay-subtitle {
    text-align: start;
    font-size: 20px;
    color: #f7e6b4;
}

.text-collapse {
    font-size: 20px;
    color: aliceblue;
}

.lines {
    margin: 7px 0;
    border-bottom: 1px solid rgb(61, 61, 61) !important;
    width: 100%;
}

.text-collpse-content {
    text-align: start;
    color: aliceblue;
    background-color: #343432;
    padding: 15px 0;
    height: 40vh
}

.overlay-about {
    margin-top: 10px;
    font-family: "Georgia Pro light";
    text-align: justify;
    text-indent: 2em;
}

.text-collpse-content img {
    float: left;
    margin: 0 10px;
    border-radius: 8px;
}

.shape {
    shape-outside: inset(0 0 0 0);
}

.org-texto {
    display: block;
    width: 90%;
}

.bg-two {
    background-color: #1a1a18;
}

@media (max-width: 1565px) {
    .overlay-name {
        font-size: 20px;
    }

    .overlay-subtitle {
        font-size: 16px;
    }
}

@media (max-width: 1800px) {

    .image-overlay img {
        width: 100%;
    }
}

@media (max-width: 1300px) {
    .overlay-name {
        font-size: 20px;
    }

    .overlay-subtitle {
        font-size: 14px;
        margin-bottom: 5px;
    }

}

@media (max-width: 1024px) {
    .card-jobs {
        flex: 0 0 33.3333%;
    }

    .overlay-name {
        font-size: 1.3rem;
        text-align: start
    }

    .overlay-subtitle {
        font-size: 1rem;
        margin-bottom: 3px;
    }
}

@media (max-width: 770px) {
    .text-collpse-content {
        height: 40vh;
        font-size: 1.3rem;
    }
}

@media (max-width: 500px) {
    .text-collpse-content {
        height: 37vh;
        font-size: 1rem;
    }
}

@media (max-width: 400px) {
    .text-collpse-content {
        height: 55vh;
        font-size: 1rem;
    }
}

@media (max-width: 350px) {
    .text-collpse-content {
        height: 83vh ;
        font-size: 1rem;
    }
}

.img-mobile {
    width: 170px;
    height: 22vh !important;
}

.VueCarousel-dot--active {
    background-color: #e1c571 !important;
}
</style>
