import Vue from 'vue'
import VueRouter from 'vue-router'
import Sections from '../views/Sections.vue'

import TourVirtual from '@/components/tourVirtual/TourVirtual.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Sections
  },
  {
    path: '/tour-virtual',
    name: 'TourVirtual',
    component: TourVirtual
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
